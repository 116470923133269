<template>
  <slot></slot>
  <div class="loading-mask" v-show="loading">
    <a-spin />
  </div>
</template>
<script lang="ts" setup>
const loading = ref(false);
provide('global-loading', loading);
</script>
<style lang="less" scoped>
.loading-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, .8);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
